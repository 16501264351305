import { inject } from '@angular/core';
import {
  MAX_FILE_UPLOAD_MB,
  MAX_FILE_UPLOAD_WS_MB,
  UPLOAD_FILE_TYPES,
} from '@configs';
import { NotificationService } from '@core/modules/snack-bar';
import { UploadType } from '@models/domain-models';
import { Observable } from 'rxjs';

export const convertBytesToMB = (bytes: number): number => {
  return bytes / (1024 * 1024);
};

export const convertMBToBytes = (mb: number): number => {
  return mb * (1024 * 1024);
};

export function createFormDataWithFile(
  payload: any extends File ? File : never
): FormData;
export function createFormDataWithFile(
  payload: any,
  options?: Partial<{ fileName: string; type: string }>
): FormData;
export function createFormDataWithFile(payload: any, options?: any): FormData {
  const formData = new FormData();
  const filename = options?.fileName || 'upload';
  const file =
    payload instanceof File
      ? payload
      : new File([JSON.stringify(payload)], filename, {
          type: options?.type,
        });

  formData.append('file', file);
  return formData;
}

export const getFileContents = (file: File): Observable<string> => {
  return new Observable(obs => {
    const reader = new FileReader();

    reader.onerror = err => obs.error(err);
    reader.onabort = err => obs.error(err);
    reader.onload = () => obs.next(reader.result?.toString());
    reader.onloadend = () => obs.complete();

    return reader.readAsText(file);
  });
};

/**
 * @deprecated use file validators instead `./file-validators`
 */
export function checkFileFactory(): (
  files: FileList,
  uploadType: UploadType
) => boolean {
  const notify = inject(NotificationService);

  return (files: FileList, uploadType: UploadType = 'WEBSOCKET'): boolean => {
    let exceeded = false;
    const maxUploadSizeMB =
      uploadType == 'REST' ? MAX_FILE_UPLOAD_MB : MAX_FILE_UPLOAD_WS_MB;

    for (const file of Array.from(files)) {
      if (file.size > convertMBToBytes(maxUploadSizeMB)) {
        notify.showError({
          message: `The uploaded file "${file.name}" exceeded the maximum upload size of ${maxUploadSizeMB} bytes`,
        });
        exceeded = true;
      }
    }

    return exceeded;
  };
}

export function generateName(fileName: string): string {
  return stripExtension(fileName).replace(/-/g, ' ');
}

export function stripExtension(filename: string): string {
  return filename.replace(/\.xml$|\.json$/, '');
}

export const fileTypeSanitiser = (input: string): string | null => {
  // Convert shorthand formats to appropriate extensions
  const formatMap: { [key: string]: string } = {
    JSON: '.json',
    XML: '.xml',
  };

  // Trim and convert the input to uppercase to match formatMap keys
  const trimmedInput = input.trim().toUpperCase();

  // Convert recognized shorthand if found
  const sanitized = formatMap[trimmedInput] || input.toLowerCase();

  // Check if the sanitized input is in the list of valid types
  if (UPLOAD_FILE_TYPES.includes(sanitized)) {
    return sanitized;
  }
  // eslint-disable-next-line no-console
  console.warn(
    `Warning: The input "${input}" is not a valid type for the HTML accept attribute.`
  );
  return null;
};
