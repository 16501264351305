import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { validateFileType } from '@app/utils/file-validators/file-type.validator';
import { BaseWorkspaceStateService } from '@core/services';
import { isServerErrorResponse } from '@models/dto';
import { ValidationError, validateFileSize } from '@utils';
import {
  BehaviorSubject,
  Observable,
  catchError,
  concatMap,
  finalize,
  first,
  from,
  last,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { NewSample, SampleState } from '../models';
import { TestPackGridSelection } from '../models/test-pack-grid-selection.model';
import { NewSampleFactory } from './new-sample.factory';
import { TransformStorageService } from './transform-storage.service';

@Injectable()
export class TransformSampleStoreService extends BaseWorkspaceStateService {
  constructor(private _storageService: TransformStorageService) {
    super();
  }

  private _disableAddSamplesSubject = new BehaviorSubject(true);
  private _isUploadingSubject = new BehaviorSubject(false);
  private _sampleListSubject = new BehaviorSubject<NewSample[] | null>(null);
  private _fileDropErrorListSubject = new BehaviorSubject<
    ValidationError[] | null
  >(null);

  private _sampleFactory = new NewSampleFactory([
    validateFileType(),
    validateFileSize(),
  ]);

  disableAddSamples$ = this._disableAddSamplesSubject.asObservable();
  isUploading$ = this._isUploadingSubject.asObservable();
  sampleList$ = this._sampleListSubject.asObservable();
  errorList$ = this._fileDropErrorListSubject.asObservable();

  get samplesCount(): number {
    return this._sampleListSubject.value?.length || 0;
  }

  addSample(
    files: FileList,
    selection: TestPackGridSelection
  ): Observable<void> {
    this._fileDropErrorListSubject.next(null);

    return this._sampleFactory.create(files, selection).pipe(
      map(fileOrError => {
        if (fileOrError instanceof NewSample) {
          this._appendSampleList(fileOrError);
        } else {
          this._appendSampleError(fileOrError);
        }
        this._checkDisableAddSamples();
      }),
      last()
    );
  }

  removeSample(newSample: NewSample): void {
    const filteredList = this._sampleList.filter(s => s !== newSample);
    this._sampleListSubject.next(filteredList.length ? filteredList : null);
    this._checkDisableAddSamples();
  }

  uploadAddedSamples(
    selection: TestPackGridSelection,
    localOrServer: boolean
  ): Observable<boolean> {
    this._isUploadingSubject.next(true);

    return this._sampleListSubject.pipe(
      first(),
      switchMap(sampleList =>
        from(sampleList.filter(sample => sample.waitingForUpload)).pipe(
          concatMap(sample => {
            sample.updateStatus(SampleState.Saving);
            return this._storageService
              .addSample(selection, sample.getTransientSample(), localOrServer)
              .pipe(
                tap(() => sample.updateStatus(SampleState.Saved)),
                catchError((e: HttpErrorResponse) => {
                  if (e.status === 400 && isServerErrorResponse(e.error)) {
                    const summary = e.error.message;
                    const details = e.error.cause;
                    sample.setError({
                      title: `Failed to add sample '${sample.name}'`,
                      summary,
                      details,
                    });
                  }
                  return of(null);
                })
              );
          })
        )
      ),
      last(),
      map(() => this._sampleListSubject.value.every(sample => sample.uploaded)),
      finalize(() => {
        this._isUploadingSubject.next(false);
        this._checkDisableAddSamples();
      })
    );
  }

  clear(): void {
    this._disableAddSamplesSubject.next(true);
    this._isUploadingSubject.next(false);
    this._sampleListSubject.next(null);
    this._fileDropErrorListSubject.next(null);
  }

  clearSuccessfulSamples(): void {
    const filteredList = this._sampleList.filter(s => !s.uploaded);

    this._isUploadingSubject.next(false);
    this._sampleListSubject.next(filteredList.length ? filteredList : null);
    this.clearErrorList();
  }

  clearErrorList(): void {
    if (this._fileDropErrorListSubject.value !== null) {
      this._fileDropErrorListSubject.next(null);
    }
  }

  override onWorkspaceSwitch(): void {
    this.clear();
  }

  private get _sampleList(): NewSample[] {
    return this._sampleListSubject?.value || [];
  }

  private _checkDisableAddSamples(): void {
    const list = this._sampleListSubject.value;
    this._disableAddSamplesSubject.next(
      !list?.some(item => item.waitingForUpload)
    );
  }

  private _appendSampleList(sample: NewSample): void {
    this._sampleListSubject.next([
      ...(this._sampleListSubject.value || []),
      sample,
    ]);
  }

  private _appendSampleError(sampleError: ValidationError): void {
    this._fileDropErrorListSubject.next([
      ...(this._fileDropErrorListSubject.value || []),
      sampleError,
    ]);
  }
}
