import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontsModule } from '@app/fonts/fonts.module';
import { StatusConfig } from './status.config';

@Component({
  standalone: true,
  imports: [CommonModule, FontsModule],
  selector: 'app-status',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      fa-icon {
        flex: 0 0 1rem;
      }
    `,
  ],
  template: `<fa-icon
    [icon]="state.icon"
    [classes]="state.classes || []"
    [styles]="state.styles"
    [ngClass]="state.themeClass"
    [title]="state.text"
  />`,
})
export class StatusComponent {
  @Input() state: StatusConfig;
}
