import { TASK_MESSAGE_LISTENERS } from '../task-listener.models';
import { GridViewGenerationListenerService } from './grid-view-generation.listener.service';
import { PojoCompilationListenerService } from './pojo-compilation.listener.service';
import { StaticCompilationListenerService } from './static-compilation.listener.service';
import { WorkspaceTaskListenerService } from './workspace-task.listener.service';

export const TaskMessageListeners = [
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: PojoCompilationListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: StaticCompilationListenerService,
    multi: true,
  },

  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: GridViewGenerationListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: WorkspaceTaskListenerService,
    multi: true,
  },
];
