import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { Observable, map } from 'rxjs';

interface Summary {
  icon: IconProp;
  className: string;
  count: number;
}

@Component({
  standalone: true,
  imports: [CommonModule, FontsModule],
  selector: 'app-no-rows-overlay',
  templateUrl: './custom-no-rows-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      @use 'partials/variables' as var;

      .summary-wrapper {
        padding: 0.5rem 1.5rem;
        border-radius: var.$border-radius;
      }
    `,
  ],
})
export class CustomNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  readonly dateViewState = transform.DateViewState;

  params!: INoRowsOverlayParams<
    transform.DataViewerRow,
    transform.DataViewerContext
  >;
  summaries$: Observable<Summary[]>;

  agInit(
    params: INoRowsOverlayParams<
      transform.DataViewerRow,
      transform.DataViewerContext
    >
  ): void {
    this.params = params;
    this.summaries$ = this.params.context.summarySubject.pipe(
      map(summary => {
        const summaries: Summary[] = [];
        if (summary.error > 0) {
          summaries.push({
            icon: 'exclamation-triangle',
            className: 'theme-color-warn',
            count: summary.error,
          });
        }
        if (summary.warning > 0) {
          summaries.push({
            icon: 'exclamation-circle',
            className: 'theme-color-yellow-alt',
            count: summary.warning,
          });
        }
        return summaries.length > 0 ? summaries : null;
      })
    );
  }
}
