import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appContentEditable]',
})
export class ContentEditableDirective {
  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2
  ) {
    this._setContentEditable(true);
  }

  @Input()
  set disabled(disable: boolean | undefined) {
    this._setContentEditable(disable !== undefined ? !disable : true);
  }

  @Output()
  contentChange = new EventEmitter<string>();

  @Output()
  isEditing = new EventEmitter<boolean>();

  @HostListener('focus') onFocus(): void {
    if (this.disabled) {
      return;
    }

    this.isEditing.emit(true);
  }

  @HostListener('blur') onBlur(): void {
    if (this.disabled) {
      return;
    }

    this.contentChange.emit(this._el.nativeElement.textContent);
    this.isEditing.emit(false);
  }

  @HostListener('keydown.enter') onEnter(): void {
    if (this.disabled) {
      return;
    }

    this._el.nativeElement.blur();
  }

  @HostListener('keydown.esc') onEsc(): void {
    if (this.disabled) {
      return;
    }

    this._el.nativeElement.blur();
  }

  private _setContentEditable(value: boolean): void {
    this._renderer.setAttribute(
      this._el.nativeElement,
      'contenteditable',
      value ? 'true' : 'false'
    );
  }
}
