<div
  class="storage-location-selection__wrapper"
  appHoverClass="theme-bg-light"
  [disabled]="disabled"
  [ngClass]="{
    'storage-location-selection__wrapper--selected': storeOnServer,
  }"
>
  <mat-checkbox [checked]="storeOnServer" [disabled]="disabled" />
  <div class="flex-column">
    <h4 class="font-weight-bold mb-0">Include sample in workspace</h4>
    <small class="mb-0 opacity-50">
      Your sample will be stored securely but will become visible to others when
      you contribute your workspace
    </small>
  </div>
</div>
