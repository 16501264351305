import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-rosetta-card',
  templateUrl: './rosetta-card.component.html',
  host: {
    class: 'rosetta-card theme-bg-alt theme-border',
  },
  styles: [
    `
      @use 'partials/variables' as var;

      .rosetta-card {
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        border-radius: var.$border-radius;
        overflow: hidden;
        height: 100%;

        &__header {
          flex-grow: 0;
          padding-inline: 1rem;

          h2 {
            font-size: 1rem;
            font-weight: bold;
            line-height: var.$panel-height-sm;
          }
        }

        &__content {
          flex: 1;
        }
      }
    `,
  ],
})
export class RosettaCardComponent {
  @Input({ required: true }) title: string;
  @Input() innerClasses: string;
}
