import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
  ViewChild,
  computed,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { NotificationService } from '@core/modules/snack-bar';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { HoverClassDirective } from '@shared/directives';
import { ContentEditableDirective } from '@shared/directives/content-editable.directive';
import { MoreOptionsMenuComponent } from '@shared/modules/more-options-menu/more-options-menu.component';
import { MoreOptionsMenuItem } from '@shared/modules/more-options-menu/more-options-menu.model';
import { RosettaOverlayModule } from '@shared/modules/rosetta-overlay/rosetta-overlay.modules';
import { ErrorMessageComponent } from '../../../error-message/error-message.component';
import { NewSample, SampleState } from '../../models';

@Component({
  standalone: true,
  selector: 'app-new-sample-card',
  templateUrl: './new-sample-card.component.html',
  styleUrls: ['./new-sample-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    FontsModule,
    MatMenuModule,
    HoverClassDirective,
    ContentEditableDirective,
    RosettaOverlayModule,
    ErrorMessageComponent,
    MoreOptionsMenuComponent,
  ],
})
export class NewSampleCardComponent implements OnInit {
  constructor(private _notify: NotificationService) {}
  @Input({ required: true })
  sample!: NewSample;

  @Output()
  delete = new EventEmitter<void>();

  isEditing = false;
  disabled: Signal<boolean>;
  sampleAdded: Signal<boolean>;
  sampleErrored: Signal<boolean>;
  statusIcon: Signal<IconProp | null>;
  statusText: Signal<string | null>;
  sampleIconClass: Signal<string>;
  backgroundClass: Signal<string>;
  hoverBackgroundClass: Signal<string>;

  actionMenuItems: MoreOptionsMenuItem[] = [
    {
      id: 'edit',
      label: 'Edit',
      icon: 'pen',
    },
    {
      id: 'delete',
      label: 'Delete',
      icon: 'trash',
    },
  ];

  @ViewChild('sampleName') private _sampleNameElement: ElementRef;

  ngOnInit(): void {
    this.disabled = computed(() => {
      const status = this.sample.status();
      return status === SampleState.Saving || status === SampleState.Saved;
    });
    this.sampleAdded = computed(
      () => this.sample.status() === SampleState.Saved
    );
    this.sampleErrored = computed(
      () => this.sample.status() === SampleState.Errored
    );
    this.statusIcon = computed(() => this._getStatusIcon());
    this.statusText = computed(() => this._getStatusText());
    this.sampleIconClass = computed(() =>
      this.sample.status() === SampleState.Saving ? '' : 'theme-color-primary'
    );
    this.hoverBackgroundClass = computed(() =>
      this.sampleErrored() ? 'error-bg-darker' : 'theme-bg-light'
    );
    this.backgroundClass = computed(() => this._getBackgroundClass());
  }

  updateName(updatedName: string, sampleNameElement: HTMLElement): void {
    if (!!updatedName) {
      this.sample.updateName(updatedName);
      return;
    }

    this._notify.showWarning({ message: 'Sample name cannot be empty.' });
    sampleNameElement.innerText = this.sample.name;
  }

  onActionSelected(actionId: string): void {
    switch (actionId) {
      case 'edit':
        // Defer focus to wait for dropdown menu to close to prevent DOM target element side effects
        setTimeout(() => {
          this._sampleNameElement.nativeElement.focus();
        }, 0);
        return;
      case 'delete':
        this.delete.emit();
        return;
    }
  }

  private _getStatusIcon(): IconProp | null {
    if (this.sampleAdded()) {
      return 'circle-check';
    }
    if (this.sampleErrored()) {
      return 'circle-xmark';
    }
    return null;
  }

  private _getBackgroundClass(): string {
    if (this.sampleAdded()) {
      return 'success-bg';
    }
    if (this.sampleErrored()) {
      return 'error-bg';
    }
    return 'theme-bg-lighter';
  }

  private _getStatusText(): string | null {
    if (this.sampleAdded()) {
      return 'Added';
    }
    if (this.sampleErrored()) {
      return 'Failed';
    }
    return null;
  }
}
