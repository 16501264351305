import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FontsModule } from '@app/fonts/fonts.module';
import { LetDirective } from '@ngrx/component';
import {
  GoToSymbolDirective,
  PersistExpandStateDirective,
  ScrollPositionDirective,
} from '@shared/directives';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CopyToClipboardComponent } from '../copy-to-clipboard/copy-to-clipboard.component';
import { RosettaCodeEditorComponent } from '../rosetta-code-editor/rosetta-code-editor.component';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { RosettaOverlayModule } from '../rosetta-overlay/rosetta-overlay.modules';
import { StopPropagationModule } from '../stop-propagation/stop-propagation.module';
import { CodeViewSearchComponent } from './code-view-search/code-view-search.component';
import { CodeViewComponent } from './code-view.component';
import { CodeViewSelectedComponent } from './components/code-view-selected/code-view-selected.component';
import { CodeViewSwitchComponent } from './components/code-view-switch/code-view-switch.component';

@NgModule({
  declarations: [
    CodeViewSwitchComponent,
    CodeViewSearchComponent,
    CodeViewSelectedComponent,
    CodeViewComponent,
  ],
  imports: [
    CommonModule,
    FontsModule,
    FormsModule,

    // Third Party
    NgScrollbarModule,
    LetDirective,

    // UI Modules
    StopPropagationModule,
    RosettaOverlayModule,

    // Material
    MatTooltipModule,
    MatTreeModule,
    MatButtonModule,

    // Standalone
    CopyToClipboardComponent,
    GoToSymbolDirective,
    PersistExpandStateDirective,
    RosettaMenuComponent,
    ScrollPositionDirective,
    RosettaCodeEditorComponent,
  ],
  exports: [CodeViewComponent, CodeViewSwitchComponent],
})
export class CodeViewModule {}
