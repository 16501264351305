import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }

    /*
    Prevent file drop globally apart from designated drop zones
    */
    const types = ['drop', 'dragover'] as const;
    types.forEach(type => {
      document.addEventListener(type, event => {
        event.preventDefault();
      });
    });
  })
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
