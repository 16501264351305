import { prefixResourcePaths } from '@utils';

export const enum ResourceType {
  Dynamic = '__Dynamic__',
  Core = '__CORE__',
}

export const CORE_RW = 'core-rw';
export const CORE_RO = 'core-ro';

export const DynamicResourcePaths = prefixResourcePaths(ResourceType.Dynamic, {
  bsp: 'api/bsp',
  engine: 'api/engine',
  navigator: 'api/navigator',
  pipeline: 'api/pipeline',
  projection: 'api/projection',
  promoted: 'api/promoted',
  report: 'api/report',
  status: 'api/status',
  symbol: 'api/symbol',
  task: 'api/task',
  ingest: 'api/translate-v1_5',
  visualisation: 'api/visualisation',
  graph: 'api/graph',
  workspace: 'api/workspace',
  functions: 'api/functions',
  validation: 'api/validation',
} as const);

export const CoreResourcePaths = prefixResourcePaths(CORE_RW, {
  assets: 'api/assets',
  contributions: 'api/contributions',
  models: 'api/models',
  notificationServer: 'api/notification',
  packagePlans: 'api/package-plans',
  products: 'api/product',
  releases: 'api/product/releases',
  scm: 'api/scm',
  statusCore: 'api/status',
  user: 'api/user',
  workspaceCore: 'api/workspace',
} as const);

export const ResourcePaths = {
  userServer: 'user/api/user',
  projectAdmin: 'user/api/project-admin',
} as const;

export const WebSocketResourcePaths = {
  languageClient: `${ResourceType.Dynamic}/ws-api/rosetta-language-server`,
  notifications: `notification/ws-api/notification`,
  serverState: `${ResourceType.Dynamic}/ws-api/server-state`,
} as const;
