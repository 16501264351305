import { ServerErrorType } from './server-error-type.enum';

export interface ServerErrorResponse {
  code: number;
  message: string;
  cause?: string;
  errorType?: ServerErrorType;
}

export function isServerErrorResponse(obj: any): obj is ServerErrorResponse {
  return (
    obj !== undefined &&
    obj !== null &&
    typeof obj === 'object' &&
    typeof obj.code === 'number' &&
    typeof obj.message === 'string' &&
    (typeof obj.cause === 'undefined' || typeof obj.cause === 'string') &&
    (typeof obj.errorType === 'undefined' ||
      Object.values(ServerErrorType).includes(obj.errorType))
  );
}
