<mat-form-field appearance="outline" class="width-full">
  <mat-label>{{ label }}</mat-label>
  <input
    #input
    matInput
    [matAutocomplete]="auto"
    [formControl]="searchControl"
    [placeholder]="placeholder"
    (input)="onSearchInput()"
    (focus)="input.select()"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    [displayWith]="displayWithFunc"
    (optionSelected)="onOptionSelected($event)"
    (closed)="checkValidSection()"
    (opened)="resetList()"
  >
    <mat-option *ngIf="filteredList() === null" [value]="null" disabled>
      No results
    </mat-option>
    <mat-option *ngFor="let result of filteredList()" [value]="result">
      <h4 class="mb-0" [innerHtml]="result.highlightLabel"></h4>
      <small
        class="theme-color-50"
        *ngIf="result?.highlightDescription"
        [innerHtml]="result.highlightDescription"
      ></small>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
