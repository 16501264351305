import { WORKSPACE_READY_TASKS } from '@configs';
import { Task } from './task.enum';
import { TaskNotification } from './task-notifications.model';

export type TaskStatusMap<T> = Partial<Record<TaskStatusKey, T>>;
export type TaskStatusKey =
  | (typeof WORKSPACE_READY_TASKS)[number]
  | Task.BspInitialised; // NOTE: BspInitialised only fires once per workspace
export const isWorkspaceTask = (
  task: TaskNotification
): task is TaskNotification<TaskStatusKey> =>
  [...WORKSPACE_READY_TASKS, Task.BspInitialised].some(
    workspaceTask => workspaceTask === task.task
  );
