import { Optional } from '@angular/core';
import {
  ValidationError,
  ValidationFunc,
  getFileContents,
  stripExtension,
} from '@utils';
import {
  Observable,
  OperatorFunction,
  catchError,
  from,
  map,
  mergeMap,
  of,
  switchMap,
} from 'rxjs';
import { NewSample } from '../models';
import { TestPackGridSelection } from '../models/test-pack-grid-selection.model';

export class NewSampleFactory {
  constructor(
    @Optional() private _validators: ReturnType<ValidationFunc>[] = []
  ) {}

  create(
    files: FileList,
    selection: TestPackGridSelection
  ): Observable<NewSample | ValidationError> {
    return from(Array.from(files)).pipe(
      mergeMap(file =>
        of(file).pipe(
          this._runValidators(),
          switchMap(file => getFileContents(file)),
          map(
            json =>
              new NewSample(
                stripExtension(file.name),
                json,
                selection,
                file.name
              )
          ),
          catchError(validatorError => of(validatorError))
        )
      )
    );
  }

  private _runValidators(): OperatorFunction<File, File> {
    return this._validators.length === 0
      ? map(file => file)
      : mergeMap(file =>
          from(
            Promise.all(
              this._validators.map(validator =>
                Promise.resolve(validator(file))
              )
            )
          ).pipe(
            map(results => {
              const validatorError = results.find(result => result !== null);
              if (validatorError) {
                throw validatorError;
              }
              return file;
            })
          )
        );
  }
}
