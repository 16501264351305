import { SUPPORTED_UPLOAD_MIME_TYPES } from '@configs';
import { ValidationFunc } from './validator.model';

export const validateFileType: ValidationFunc = () => {
  return file => {
    if (!SUPPORTED_UPLOAD_MIME_TYPES.includes(file.type)) {
      return {
        fileName: file.name,
        reason: file.type
          ? `File type '${file.type}' is not supported.`
          : 'File type unrecognised.',
      };
    }
    return null;
  };
};
