import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { SlideDownAnimation, SlideUpAnimation } from '@shared/animations';
import { SampleRowAction } from '@shared/modules/transform/models/sample-action.enum';
import { TransformDataViewerComponent } from '@shared/modules/transform/components/transform-data-viewer/transform-data-viewer.component';
import { addUnique } from '@utils';
import { CommonModule } from '@angular/common';
import { FontsModule } from '@app/fonts/fonts.module';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [CommonModule, FontsModule, MatButtonModule],
  selector: 'app-action-bar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './action-bar.component.html',
  styles: [
    `
      @use 'partials/variables' as var;

      .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem 0.5rem 0.5rem;
        border-radius: var.$border-radius;
        background-color: rgba(0, 0, 0, 0.03);

        button {
          margin-right: 0.5rem;
        }
      }

      .selected-text {
        white-space: nowrap;
      }
    `,
  ],
  animations: [SlideUpAnimation, SlideDownAnimation],
})
export class ActionBarComponent {
  constructor(private _parent: TransformDataViewerComponent) {}

  @Input()
  set selectedRows(value: transform.DataViewerRow[]) {
    value ||= [];
    this._updateSelectedRowLength(value);
    this._getAggregatedActions(value);
    this._selectedRows = value;
  }
  get selectedRows(): transform.DataViewerRow[] {
    return this._selectedRows;
  }
  private _selectedRows: transform.DataViewerRow[] = [];

  readonly sampleRowAction = SampleRowAction;

  @Input() loading = false;
  @Input() showChanges = false;

  @Output() applyAction = new EventEmitter<transform.DataViewerEvent>();

  selectedRowLength = 0;
  actions: SampleRowAction[] = [];

  onClick(type: transform.DataViewerEvent['type']) {
    this.applyAction.emit({
      type,
      rows: this.selectedRows,
      testPackGridSelection:
        this._parent.context.dataViewer.testPackGridSelection,
      context: this._parent.context,
    });
  }

  private _updateSelectedRowLength(rows: transform.DataViewerRow[]) {
    this.selectedRowLength = rows.length;
  }

  private _getAggregatedActions(rows: transform.DataViewerRow[]) {
    this.actions = addUnique([], ...rows.map(row => row.actions).flat());
  }
}
