import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ENGINE_ACCESS } from '@configs';
import {
  PermissionGuardFunc,
  QueryParamGuard,
  RedirectGuard,
  WorkspaceAccessGuard,
} from '@core/guards';
import { CustomRoutes, RouteData } from '@models';
import { closeWorkspaceGuard } from './close-workspace.guard';
import { WorkspaceMenuComponent } from './components/workspace-menu/workspace-menu.component';
import { EngineFeatureTabs } from './modules/engine/engine-config';
import { WorkspaceContainerComponent } from './pages/workspace-container/workspace-container.component';

export const ENGINE_CHILDREN_ROUTE_DATA: RouteData = {
  bottomPanel: true,
  abilityName: ENGINE_ACCESS,
  applyTo: 'rw',
};

const routes: CustomRoutes = [
  {
    path: '',
    component: WorkspaceContainerComponent,
    canActivate: [WorkspaceAccessGuard, RedirectGuard, QueryParamGuard],
    canDeactivate: [closeWorkspaceGuard],
    data: {
      navBarTitle: WorkspaceMenuComponent,
    },
    canActivateChild: [PermissionGuardFunc],
    children: [
      {
        path: EngineFeatureTabs.ingest.url,
        title: 'Workspace | Ingest - Rosetta',
        data: ENGINE_CHILDREN_ROUTE_DATA,
        canMatch: [PermissionGuardFunc],
        loadChildren: () =>
          import(
            '@features/workspace/modules/engine/ingest/ingest.module'
          ).then(m => m.IngestModule),
      },
      {
        path: EngineFeatureTabs.enrich.url,
        title: 'Workspace | Enrich - Rosetta',
        canLoad: [PermissionGuardFunc],
        data: ENGINE_CHILDREN_ROUTE_DATA,
        loadChildren: () =>
          import(
            '@features/workspace/modules/engine/enrich/enrich.module'
          ).then(m => m.EnrichModule),
      },
      {
        path: EngineFeatureTabs.reports.url,
        title: 'Workspace | Reports - Rosetta',
        canLoad: [PermissionGuardFunc],
        data: ENGINE_CHILDREN_ROUTE_DATA,
        loadChildren: () =>
          import(
            '@features/workspace/modules/engine/reports/reports.module'
          ).then(m => m.ReportsModule),
      },
      {
        path: EngineFeatureTabs.projection.url,
        title: 'Workspace | Projection - Rosetta',
        canLoad: [PermissionGuardFunc],
        data: ENGINE_CHILDREN_ROUTE_DATA,
        loadChildren: () =>
          import(
            '@features/workspace/modules/engine/projection/projection.module'
          ).then(m => m.ProjectionModule),
      },
      {
        path: EngineFeatureTabs.validation.url,
        title: 'Workspace | Validation - Rosetta',
        data: ENGINE_CHILDREN_ROUTE_DATA,
        loadChildren: () =>
          import(
            '@features/workspace/modules/engine/validation/validation.module'
          ).then(m => m.ValidationModule),
      },
      {
        path: EngineFeatureTabs.visualisation.url,
        title: 'Workspace | Visualisation - Rosetta',
        data: ENGINE_CHILDREN_ROUTE_DATA,
        loadChildren: () =>
          import('@workspace-engine/visualisation/visualisation.module').then(
            m => m.VisualisationModule
          ),
      },
      {
        path: EngineFeatureTabs.apiExport.url,
        title: 'Workspace | API Export - Rosetta',
        data: ENGINE_CHILDREN_ROUTE_DATA,
        loadChildren: () =>
          import('@workspace-engine/api-export/api-export.module').then(
            m => m.ApiExportModule
          ),
      },
      {
        path: EngineFeatureTabs.functions.url,
        title: 'Workspace | Functions - Rosetta',
        data: ENGINE_CHILDREN_ROUTE_DATA,
        loadChildren: () =>
          import('@workspace-engine/functions/engine-functions.module').then(
            m => m.EngineFunctionsModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkspaceRoutingModule {}
