import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MONACO_LOADER,
  MonacoLoader,
} from '@features/workspace/modules/design/textual/services/monaco-loader';
import { defaultConfig } from './default.config';

export interface RosettaEditorModel {
  value: string;
  language?: string;
  uri?: monaco.Uri;
}

export interface RosettaEditorModelChangeEvent {
  editorValue: string;
  event: monaco.editor.IModelContentChangedEvent;
}

@Component({
  standalone: true,
  selector: 'app-rosetta-code-editor',
  template: `<div
    class="rosetta-code-editor__container"
    #codeEditorContainer
  ></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'rosetta-code-editor',
  },
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }

      .rosetta-code-editor {
        &__container {
          height: 100%;
          overflow: hidden;
        }
      }
    `,
  ],
})
export class RosettaCodeEditorComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  constructor(@Inject(MONACO_LOADER) private _loader: MonacoLoader) {}

  @ViewChild('codeEditorContainer', { static: true })
  _editorContainer: ElementRef;

  @Input() model?: RosettaEditorModel;
  @Input() options: monaco.editor.IStandaloneEditorConstructionOptions = {};

  @Output() editorInit =
    new EventEmitter<monaco.editor.IStandaloneCodeEditor>();
  @Output() contentChanged = new EventEmitter<RosettaEditorModelChangeEvent>();

  private _editor?: monaco.editor.IStandaloneCodeEditor;

  ngAfterViewInit(): void {
    this._loader.loadMonaco().then(() => {
      this._initEditor();
    });
  }

  ngOnChanges({ options, model }: SimpleChanges): void {
    if (options?.currentValue) {
      this._updateOptions();
    }
    if (model?.currentValue) {
      this._updateModel();
    }
  }

  ngOnDestroy(): void {
    if (this._editor) {
      this._editor.dispose();
    }
  }

  private _updateOptions(): void {
    this.options = { ...defaultConfig, ...this.options };
    if (this._editor) {
      this._editor.dispose();
      this._initEditor();
    }
  }

  private _updateModel(): void {
    if (this._editor) {
      this._editor.dispose();
      this._initEditor();
    }
  }

  private _initEditor(): void {
    const model = this._getModel();

    this._editor = monaco.editor.create(this._editorContainer.nativeElement, {
      ...this.options,
      model,
    });

    this._editor.onDidChangeModelContent(event => {
      this.contentChanged.emit({ editorValue: this._editor.getValue(), event });
    });

    this.editorInit.emit(this._editor);
  }

  private _getModel(): monaco.editor.ITextModel {
    if (!this.model) {
      return monaco.editor.createModel('');
    }

    return this.model.uri
      ? monaco.editor.getModel(this.model.uri)
      : monaco.editor.createModel(
          this.model.value,
          this.model.language,
          this.model.uri
        );
  }
}
