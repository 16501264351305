import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
  booleanAttribute,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appHoverClass]',
})
export class HoverClassDirective {
  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2
  ) {}

  @Input({ transform: booleanAttribute }) disabled: false;

  @Input({ required: true, alias: 'appHoverClass' }) set hoverClass(
    hoverClass: string
  ) {
    this._reset();
    this._hoverClass = hoverClass;
  }

  @Input() set baseClass(baseClass: string | undefined) {
    this._reset();
    this._baseClass = baseClass;
    baseClass && this._renderer.addClass(this._el.nativeElement, baseClass);
  }

  private _hoverClass?: string;
  private _baseClass?: string;

  @HostBinding('class.cursor-pointer')
  get cursorPointerClass(): boolean {
    return !this.disabled;
  }

  @HostListener('mouseover') onMouseOver(): void {
    if (this.disabled) {
      this._removeClass();
    } else {
      this._addClass();
    }
  }

  @HostListener('mouseout') onMouseOut(): void {
    this._removeClass();
  }

  private _addClass(): void {
    this._renderer.removeClass(this._el.nativeElement, this._baseClass);
    this._renderer.addClass(this._el.nativeElement, this._hoverClass);
  }

  private _removeClass(): void {
    this._renderer.removeClass(this._el.nativeElement, this._hoverClass);
    this._renderer.addClass(this._el.nativeElement, this._baseClass);
  }

  private _reset(): void {
    this._renderer.removeClass(this._el.nativeElement, this._baseClass);
    this._renderer.removeClass(this._el.nativeElement, this._hoverClass);
  }
}
