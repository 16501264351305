<app-rosetta-file-drop
  *ngrxLet="isUploading$ as isUploading"
  (filesDropped)="onFileDropped($event)"
  [disabled]="isUploading"
>
  <header mat-dialog-title>
    <h2>Add Sample</h2>
    <div class="spacer"></div>
    <button
      mat-icon-button
      [disabled]="isUploading"
      [mat-dialog-close]="newSamplesUploaded()"
      tabindex="-1"
    >
      <fa-icon icon="times" />
    </button>
  </header>

  <!--
  Extra wrapper required to prevent default style to
  remove top padding when content is next to the title.
-->
  <div>
    <mat-dialog-content>
      <app-inline-error
        class="mb-16 spacer flex-0"
        @inOutAnimation
        *ngIf="errorList$ | async as errorList"
      >
        <p class="mb-0" *ngFor="let error of errorList">
          <strong>{{ error.fileName }}</strong> : {{ error.reason }}
        </p>
      </app-inline-error>

      <app-inline-error
        class="mb-16 spacer flex-0"
        @inOutAnimation
        *ngIf="generalError$ | async; let errorMessage"
      >
        <p class="mb-0">{{ errorMessage }}</p>
      </app-inline-error>

      <div class="field-row field-row-gap mb-16">
        <mat-form-field appearance="outline">
          <mat-label>{{ pipelineLabel }}</mat-label>
          <input
            matInput
            readonly
            [value]="selection.pipelineDef.name"
            [title]="selection.pipelineDef.name"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ testPackLabel }}</mat-label>
          <input
            matInput
            readonly
            [value]="selection.testPackDef.name"
            [title]="selection.testPackDef.name"
          />
        </mat-form-field>
      </div>

      <div class="field-row mb-16">
        <mat-form-field appearance="outline">
          <mat-label>Input type</mat-label>
          <input
            matInput
            readonly
            [value]="selection.pipelineDef.transformInputType"
            [title]="selection.pipelineDef.transformInputType"
          />
        </mat-form-field>
      </div>

      <app-storage-location-selection
        [(storeOnServer)]="storeOnServer"
        [disabled]="isUploading"
        *appShowWhenWorkspace="'read-write'"
        class="mb-16"
      />

      <div
        class="file-drop-zone theme-bg flex-align-center"
        [ngClass]="(newSampleList$ | async) ? 'flex-0' : 'flex-1'"
      >
        <div class="spacer"></div>

        <h3 class="mb-4">Drag and drop here or</h3>
        <app-rosetta-file-select
          (filesSelected)="onFileDropped($event)"
          multiple="true"
          label="Select files"
          class="theme-bg-light"
          [accept]="fileType"
          [disabled]="isUploading"
        />

        <div class="spacer"></div>

        <div class="width-full flex-space-between theme-color-50">
          <small
            >File format: <strong>{{ fileType }}</strong>
          </small>
          <small
            >Upload up to <strong>{{ maxSamplesCount }} files</strong> at once,
            each up to <strong>{{ maxFileSizeMb }} MB</strong></small
          >
        </div>
      </div>

      <app-new-sample-list
        *ngIf="newSampleList$ | async; let samples"
        class="flex-1 mt-8"
        [samples]="samples"
        [disabled]="isUploading"
        (deleteSample)="onDeleteSample($event)"
        (deleteAll)="onDeleteAllSamples()"
      />
    </mat-dialog-content>

    <mat-dialog-actions>
      <button
        mat-button
        [mat-dialog-close]="newSamplesUploaded()"
        [disabled]="isUploading"
      >
        Close
      </button>
      <button
        mat-stroked-button
        (click)="uploadAddedSamples()"
        [disabled]="isUploading || (disableAddSamples$ | async)"
      >
        <fa-icon icon="plus" *appShowSpinner="!isUploading; diameter: 18" />
        Add
      </button>
    </mat-dialog-actions>
  </div>
</app-rosetta-file-drop>
