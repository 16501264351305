import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FontsModule } from '@app/fonts/fonts.module';
import { StopPropagationModule } from '@shared/modules/stop-propagation/stop-propagation.module';
import { deepCopy, deepEquals, isEmptyString, isString } from '@utils';
import Fuse, { FuseResult, IFuseOptions } from 'fuse.js';

export interface SanitizedItem<T> {
  readonly value: T;
  readonly label: string;
  readonly highlightLabel: string;
  readonly highlightDescription?: string;
}

export type RosettaTypeAheadSelectorLabelFunc<T> = (item: T) => string;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    StopPropagationModule,
  ],
  selector: 'app-rosetta-type-ahead-selector',
  templateUrl: './rosetta-type-ahead-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class RosettaTypeAheadSelectorComponent<T = any> implements OnChanges {
  @Input({ required: true }) list: T[] = [];
  @Input({ required: true }) getLabel: RosettaTypeAheadSelectorLabelFunc<T>;
  @Input() getDescription: RosettaTypeAheadSelectorLabelFunc<T>;
  @Input() fuseOptions: IFuseOptions<SanitizedItem<T>> = {};
  @Input() label = 'Type';
  @Input() placeholder = 'Type to filter selection';
  @Input() initialSelection: T;

  @Output() changed = new EventEmitter<T>();

  filteredList = signal<SanitizedItem<T>[] | null>(null);
  searchControl = new FormControl<SanitizedItem<T> | string>('');
  private _fuse: Fuse<SanitizedItem<T>>;
  private _sanitizedList: SanitizedItem<T>[] = [];
  private _selectedItem: SanitizedItem<T>;

  displayWithFunc = (item: SanitizedItem<T>): string => item?.label;

  ngOnChanges({ list, initialSelection }: SimpleChanges): void {
    if (list && this.list) {
      this._initFuse(this.list);
      this.resetList();
    }
    if (initialSelection && this.initialSelection) {
      this._setInitialSelection();
    }
  }

  onSearchInput(): void {
    const query = String(this.searchControl.value);

    if (isEmptyString(query)) {
      this.resetList();
    } else {
      const result = this._fuse.search(query);
      const resultItems = result.map(this._processResult);
      this._updateFilteredList(resultItems);
    }
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent): void {
    const selectedItem = $event.option.value as SanitizedItem<T>;
    if (selectedItem) {
      this._selectedItem = selectedItem;
      this.changed.emit(selectedItem.value);
    }
  }

  checkValidSection(): void {
    if (!isString(this.searchControl.value)) {
      return;
    }
    const query = this.searchControl.value;
    const isValidSelection =
      !!query && this._sanitizedList.some(item => item.value === query);

    if (isEmptyString(query) || !isValidSelection) {
      this.searchControl.setValue(this._selectedItem);
    }
  }

  resetList(): void {
    this._updateFilteredList(this._sanitizedList);
  }

  private _processResult(
    result: FuseResult<SanitizedItem<T>>
  ): SanitizedItem<T> {
    const item = deepCopy(result.item);
    const highlightedFields: Record<string, string> = {};

    if (result.matches) {
      result.matches.forEach(match => {
        const input = match.value;

        if (input) {
          let highlightedString = '';
          let currentIndex = 0;

          match.indices.forEach(([start, end]) => {
            highlightedString += input.slice(currentIndex, start);
            highlightedString += `<mark class="theme-color-primary-alt font-weight-bold theme-bg-none">${input.slice(start, end + 1)}</mark>`;
            currentIndex = end + 1;
          });

          highlightedString += input.slice(currentIndex);
          highlightedFields[match.key] = highlightedString;
        }
      });
    }

    return { ...item, ...highlightedFields };
  }

  private _updateFilteredList(list: SanitizedItem<T>[]): void {
    this.filteredList.set(list.length ? list : null);
  }

  private _initFuse(list: T[]): void {
    this._sanitizedList = list.map(value => {
      const label = this.getLabel(value);
      const description = this.getDescription && this.getDescription(value);
      return {
        value,
        label,
        highlightLabel: label,
        highlightDescription: description,
      };
    });

    this._fuse = new Fuse<SanitizedItem<T>>(this._sanitizedList, {
      threshold: 0.6,
      location: 0,
      distance: 100,
      findAllMatches: true,
      shouldSort: true,
      includeMatches: true,
      minMatchCharLength: 2,
      ...this.fuseOptions,
      keys: [{ weight: 2, name: 'highlightLabel' }, 'highlightDescription'],
    });
  }

  private _setInitialSelection(): void {
    const item = this._sanitizedList.find(item =>
      deepEquals(item.value, this.initialSelection)
    );
    this._selectedItem = item;
    this.searchControl.setValue(item);
  }
}
