import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FontsModule } from '@app/fonts/fonts.module';
import { MoreOptionsMenuItem } from './more-options-menu.model';

@Component({
  standalone: true,
  selector: 'app-more-options-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, FontsModule, MatMenuModule],
  template: `
    <button mat-icon-button [matMenuTriggerFor]="moreOptionsMenu">
      <fa-icon icon="ellipsis-v" />
    </button>

    <mat-menu #moreOptionsMenu="matMenu" xPosition="before">
      <ng-template matMenuContent>
        <a
          *ngFor="let item of menuItems"
          (click)="selected.emit(item.id)"
          mat-menu-item
        >
          <fa-icon *ngIf="!!item.icon" [icon]="item.icon" />
          {{ item.label }}
        </a>
      </ng-template>
    </mat-menu>
  `,
  styles: [
    `
      @import 'partials/icon';

      .mat-mdc-menu-item fa-icon {
        @extend %menu-icon;
      }
    `,
  ],
})
export class MoreOptionsMenuComponent {
  @Input() menuItems: MoreOptionsMenuItem[];
  @Output() selected = new EventEmitter<string>();
}
