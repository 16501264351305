import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  booleanAttribute,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { HoverClassDirective } from '@shared/directives';

@Component({
  standalone: true,
  selector: 'app-storage-location-selection',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./storage-location-selection.component.scss'],
  templateUrl: './storage-location-selection.component.html',
  host: { class: 'theme-bg-lighter' },
  imports: [
    CommonModule,
    MatRippleModule,
    MatCheckboxModule,
    HoverClassDirective,
  ],
})
export class StorageLocationSelectionComponent {
  @Input({ transform: booleanAttribute }) storeOnServer = false;
  @Input({ transform: booleanAttribute }) disabled = false;
  @Output() storeOnServerChange = new EventEmitter<boolean>();

  @HostListener('click')
  toggleStoreOnServer(): void {
    if (this.disabled) return;
    this.storeOnServer = !this.storeOnServer;
    this.storeOnServerChange.emit(this.storeOnServer);
  }
}
