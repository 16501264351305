<div
  class="file-drop"
  [class.file-drop--drag-active]="isDragging()"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)"
>
  <div class="file-drop__content">
    <ng-content></ng-content>
  </div>

  <div class="file-drop__zone theme-bg" *ngIf="isDragging()">
    <div
      class="file-drop__zone-inner theme-border-dark flex-align-center-center"
    >
      <fa-icon icon="file-circle-plus" size="2xl" />
      <h3 class="font-weight-bold mb-0">Drop files to add</h3>
    </div>
  </div>
</div>
