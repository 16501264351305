import { FILTER_DELIMITER } from '@configs';
import { CodeViewWithDiagnosticsDTO } from '@models/dto';
import {
  Diagnostic,
  DiagnosticGroup,
  DiagnosticResultKey,
  DiagnosticSection,
} from '@shared/modules/diagnostic-panel/diagnostic.model';
import { TransformDTO } from './../transform/models/transform-dto.model';

export function mapDiagnosticData(
  filter: string,
  diagnosticData: CodeViewWithDiagnosticsDTO
): Diagnostic {
  const resultMap = new Map<DiagnosticResultKey, DiagnosticGroup[]>();

  const validation =
    diagnosticData.diagnostics?.diagnosticRecords?.validation?.results;
  if (validation) {
    mapResultsToDiagnostic(filter, 'validation', validation, resultMap);
  }

  const mapping =
    diagnosticData.diagnostics?.diagnosticRecords?.mapping?.results;
  if (mapping) {
    mapResultsToDiagnostic(filter, 'mapping', mapping, resultMap);
  }

  const qualification =
    diagnosticData.diagnostics?.diagnosticRecords?.qualification?.results;
  if (qualification) {
    mapResultsToDiagnostic(filter, 'qualification', qualification, resultMap);
  }

  return resultMap;
}

function mapResultsToDiagnostic(
  filter: string,
  key: DiagnosticResultKey,
  diagnosticResults: TransformDTO.DiagnosticResults,
  resultMap: Diagnostic
): void {
  const successItems = diagnosticResults.successes?.details;
  const failureItems = diagnosticResults.failures?.details;
  const excludedItems = diagnosticResults.excluded?.details;
  const filterTest = createFilterTest(filter);

  const successes: DiagnosticGroup = successItems?.reduce<DiagnosticGroup>(
    (acc, item) => diagnosticGroupReducer(filterTest, acc, item),
    {
      def: 'success',
      name: 'Successes',
      color: '#018301', // Green
      sections: [],
      actual: diagnosticResults.successes?.actual,
      expected: diagnosticResults.successes?.expected,
    }
  );

  const failures: DiagnosticGroup = failureItems?.reduce<DiagnosticGroup>(
    (acc, item) => diagnosticGroupReducer(filterTest, acc, item),
    {
      def: 'failure',
      name: 'Failures',
      color: '#d83737', // Red
      sections: [],
      actual: diagnosticResults.failures?.actual,
      expected: diagnosticResults.failures?.expected,
    }
  );

  const diagnosticGroups = [failures, successes];

  if (diagnosticResults.excluded) {
    let excluded: DiagnosticGroup = {
      def: 'excluded',
      name: 'Excluded',
      color: '#949494', // Grey
      useLightTextColor: true,
      sections: [],
      actual: diagnosticResults.excluded?.actual,
      expected: diagnosticResults.excluded?.expected,
    };

    if (excludedItems?.length > 0) {
      excluded = excludedItems.reduce<DiagnosticGroup>(
        (acc, item) => diagnosticGroupReducer(filterTest, acc, item),
        excluded
      );
    }

    diagnosticGroups.push(excluded);
  }

  resultMap.set(key, diagnosticGroups);
}

function createFilterTest(filterStr: string): (item: any) => boolean {
  const filterRegExp = new RegExp(filterStr, 'mi');
  return (item: any): boolean => {
    const itemStr = Object.values(item)
      .map(value => (typeof value === 'string' ? value : ''))
      .join(FILTER_DELIMITER);
    return filterRegExp.test(itemStr);
  };
}

function diagnosticGroupReducer(
  filterTest: (item: any) => boolean,
  acc: DiagnosticGroup,
  item: TransformDTO.DiagnosticResultDetails
) {
  if (!filterTest(item)) {
    return acc;
  }

  let section: DiagnosticSection | undefined = acc.sections.find(
    section => section.category === item.category
  );
  if (!section) {
    section = {
      category: item.category,
      items: [],
    };
    acc.sections.push(section);
  }

  section.items.push({
    name: item.name,
    inputPath: item.inputPath,
    outputPaths: item.outputPaths,
    issues: item.issues,
  });

  return acc;
}
