import { getErrorMessage } from '@utils';
import { OperatorFunction, catchError, of } from 'rxjs';
import { PipelineRunInfo } from '../models';

export const pipelineCatchError = (): OperatorFunction<
  any,
  PipelineRunInfo
> => {
  return catchError(error => {
    const pipelineRunUpdate: PipelineRunInfo = {
      result: null,
      errorMessage: getErrorMessage(error) || 'An unexpected error occurred.',
    };
    return of(pipelineRunUpdate);
  });
};
