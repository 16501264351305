export enum Task {
  // General
  BspInitialised = 'BspInitialised',
  PojoCompilation = 'PojoCompilation',
  StaticJavaCompilation = 'StaticJavaCompilation',
  GridViewGeneration = 'GridViewGeneration',
  ExecutionEngineInitialisation = 'ExecutionEngineInitialisation',

  // Panel specific tasks
  IngestionExport = 'IngestionExport',
}
