<app-rosetta-overlay #errorMessageOverlay>
  <app-error-message *ngIf="sample.errorReason" [error]="sample.errorReason" />
</app-rosetta-overlay>

<div
  class="sample-item__container"
  [appHoverClass]="hoverBackgroundClass()"
  [baseClass]="backgroundClass()"
  [class.disabled]="disabled()"
  [appRosettaOverlayHoverTarget]="errorMessageOverlay"
>
  <div
    class="status-tab"
    [class.success]="sampleAdded()"
    [class.error]="sampleErrored()"
  ></div>

  <fa-icon
    class="sample-item__icon"
    [class]="sampleIconClass()"
    [class.success-color]="sampleAdded()"
    [class.error-color]="sampleErrored()"
    [icon]="sample.fileName ? 'file-arrow-up' : 'file-pen'"
    size="xl"
  />

  <div class="sample-item__details flex-column">
    <div class="sample-name" matTooltip="Edit">
      <p
        #sampleName
        class="mb-0 font-weight-bold"
        appContentEditable
        [disabled]="disabled()"
        (contentChange)="updateName($event, sampleName)"
        (isEditing)="isEditing = $event"
      >
        {{ sample.name }}
      </p>
    </div>
    <small class="color-50">{{ sample.fileName ?? 'Created' }} </small>
  </div>

  <div class="spacer"></div>

  <div class="sample-item__status" *ngIf="statusIcon(); let statusIcon">
    <small
      *ngIf="statusText(); let statusText"
      class="mr-8"
      [class.success-color]="sampleAdded()"
      [class.error-color]="sampleErrored()"
      >{{ statusText }}</small
    >
    <fa-icon
      class="status-icon"
      [class.success-color]="sampleAdded()"
      [class.error-color]="sampleErrored()"
      [icon]="statusIcon"
      size="lg"
    />
  </div>

  <div class="sample-item__actions">
    <app-more-options-menu
      *ngIf="!disabled()"
      [menuItems]="actionMenuItems"
      (selected)="onActionSelected($event)"
    />
  </div>
</div>
