import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RosettaTitleCasePipe } from '@shared/pipes';
import { TransformAddSampleDialogComponent } from './components/transform-add-sample-dialog/transform-add-sample-dialog.component';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from './models/transform-config.model';
import { TransformDetailsComponent } from './pages/transform-details/transform-details.component';
import { TransformListComponent } from './pages/transform-list/transform-list.component';
import { IngestApiService } from './services/ingest-api.service';
import { TransformSelectorManagerService } from './services/selectors/transform-selector-manager.service';
import { TransformLocalStorageService } from './services/transform-local-storage.service';
import { TransformSampleStoreService } from './services/transform-sample-store.service';
import { TransformServerStorageService } from './services/transform-server-storage.service';
import { TransformStorageService } from './services/transform-storage.service';
import { TransformService } from './services/transform.service';
import * as TransformEffects from './store/transform.effects';
import { TransformRoutingModule } from './transform-routing.module';

@NgModule({
  imports: [
    TransformRoutingModule,

    // Third Party
    EffectsModule.forFeature([TransformEffects]),

    // Standalone
    TransformListComponent,
    TransformDetailsComponent,
    TransformAddSampleDialogComponent,
  ],
  providers: [
    IngestApiService,
    RosettaTitleCasePipe,
    TransformLocalStorageService,
    TransformSampleStoreService,
    TransformSelectorManagerService,
    TransformServerStorageService,
    TransformService,
    TransformStorageService,
  ],
})
export class TransformModule {
  static forFeature(
    config: ITransformConfig
  ): ModuleWithProviders<TransformModule> {
    return {
      ngModule: TransformModule,
      providers: [{ provide: TRANSFORM_CONFIG, useValue: config }],
    };
  }
}
